import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";

const InformationView = () => {
  const { name, id, attendeeType,email } = useParams();
  const handleHomeClick = () => {
    window.location.href = "https://nftexpodubai.com/";
  };

  return (
    <Layout showRegistrationText={false}>
      <div className="flex flex-col items-center justify-center w-full">
        <div className="p-6 rounded shadow-lg text-black bg-[#b224ef] w-full max-w-md">
          <div className="flex items-center justify-center mb-10">
            <h2 className="text-white text-[22px] font-[800] text-center">
              Your Pass Information
            </h2>
          </div>
          <div className="flex flex-col gap-1 items-center justify-center w-full text-white font-normal mt-3">
            <div className="flex justify-between w-full">
              <h2 className="w-1/2 sm:w-auto text-sm sm:text-xl">Name</h2>
              <span className="w-1/2 sm:w-auto text-right sm:text-xl">
                <p>{name}</p>
              </span>
            </div>

            <div className="flex justify-between w-full">
              <h2 className="w-1/2 sm:w-auto text-sm sm:text-xl">Email</h2>
              <span className="w-1/2 sm:w-auto text-right sm:text-xl">
              <p className="break-all">{email}</p>
              </span>
            </div>
            <div className="flex justify-between w-full">
              <h2 className="w-1/2 sm:w-auto text-sm sm:text-xl">ID</h2>
              <span className="w-1/2 sm:w-auto text-right sm:text-xl">
                <p>{id}</p>
              </span>
            </div>

            <div className="flex justify-between w-full">
              <h2 className="w-1/2 sm:w-auto text-sm sm:text-xl">
                Attendee Type
              </h2>
              <span className="w-1/2 sm:w-auto text-right sm:text-xl">
                <p>{attendeeType}</p>
              </span>
            </div>
          </div>
        </div>
        <button
          className="text-center bg-[#0061a8] text-white py-[10px] px-[20px] border border-[#ccc] rounded-[4px] flex-grow-0 mt-4 max-w-[28rem] w-full"
          onClick={handleHomeClick}
          style={{
            boxShadow: "0 10px 45px 0 rgba(0,0,0,.15)",
            backgroundImage: "linear-gradient(90deg, #9000A3 0%, #000000 100%)",
          }}
        >
          Back To Home
        </button>
      </div>
    
    </Layout>
  );
};

export default InformationView;
