import React, { useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faFlag,
  faPhoneFlip,
  faUser,
  faUserTag,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { RingLoader } from "react-spinners";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationModal from "../../components/SuccessModal";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;

const Home = ({ userType }) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  const [attendee, setAttendeeType] = useState("Sponsor");
  const [loading, setLoading] = useState(false); // State for loader

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
    address: Yup.string(),
    companyName: Yup.string().required("Company name is required"),
    designation: Yup.string().required("Designation is required"),
    productServices: Yup.string().required("Product/services are required"),
    representative: Yup.string().required("Representative is required"),
    sponsorshipType: Yup.string().required("Sponsorship type is required"),
    message: Yup.string().required("Message is required"),
  });

  const handleHomeClick = () => {
    window.location.href = "https://nftexpodubai.com/";
    setShowModal(false);
    setAttendeeType("");
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <Layout
      paragraph={
        "Thank you for your interest in becoming a sponsor for the NFT Expo Dubai. Your support will play a crucial role in making this event a resounding success. Please fill out the form below with your details, and we will be in touch to discuss the exciting sponsorship opportunities available. We look forward to partnering with you and showcasing your brand to a global audience."
      }
      userType={userType}
      showRegistrationText={true}
    >
      {!showModal && (
        <Formik
          initialValues={{
            name: "",
            email: "",
            phoneNumber: "+971",
            address: "",
            companyName: "",
            designation: "",
            productServices: "",
            representative: "",
            sponsorshipType: "",
            message: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setLoading(true); // Show loader when submitting

            axios
              .post(
                "https://node-project.gfmb.gfmbroker.com/users/sponser/register",
                values
              )
              .then((response) => {
                console.log("Response:", response);
                setData(response?.data);
                resetForm();
                setShowModal(true);
              })
              .catch((error) => {
                console.error("Error:", error);
              })
              .finally(() => {
                setSubmitting(false);
                setLoading(false);
              });
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <>
              <div className="flex justify-end mr-4">
                <button
                  className="text-center bg-[#0061a8] text-white py-[10px] px-[20px] border border-[#ccc] rounded-[16px]  max-w-[10rem] w-full mb-4"
                  onClick={handleHomeClick}
                  style={{
                    boxShadow: "0 10px 45px 0 rgba(0,0,0,.15)",
                    backgroundImage:
                      "linear-gradient(90deg, #9000A3 0%, #000000 100%)",
                  }}
                >
                  Back To Home
                </button>
              </div>
              <Form>
                {loading ? (
                  <div className="flex justify-center mt-4">
                    <RingLoader
                      color={"#ffffff"}
                      loading={loading}
                      css={override}
                      size={50}
                    />
                  </div>
                ) : (
                  <div className="flex flex-col gap-6">
                    <div className="flex gap-4 sm:gap-0 flex-col sm:flex-row">
                      <div className="w-full px-[15px]">
                        <div className="w-full flex items-center bg-white border rounded-[4px]">
                          <span className="z-10 px-3 py-[6px]">
                            <FontAwesomeIcon icon={faUser} color="black" />
                          </span>
                          <Field
                            type="text"
                            name="name"
                            placeholder="Enter Your Name"
                            className="w-full px-3 py-[6px] text-[13px] text-black h-[42px] leading-[33px] bg-transparent outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <div className="w-full px-[15px]">
                        <div className="w-full flex items-center bg-white border rounded-[4px]">
                          <span className="px-3 py-[6px]">
                            <FontAwesomeIcon icon={faEnvelope} color="black" />
                          </span>
                          <Field
                            type="email"
                            name="email"
                            placeholder="Enter Your Email"
                            className="w-full px-3 py-[6px] text-[13px] text-black h-[42px] leading-[33px] bg-transparent outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                    <div className="flex gap-4 sm:gap-0 flex-col sm:flex-row">
                      <div className="w-full px-[15px]">
                        <div className="w-full flex items-center bg-white border rounded-[4px]">
                          <span className="z-10 px-3 py-[6px]">
                            <FontAwesomeIcon icon={faPhoneFlip} color="black" />
                          </span>
                          <Field
                            name="phoneNumber"
                            type="text"
                            placeholder="Enter Your Phone Number"
                            onKeyDown={(e) => {
                              if (
                                !/^\d$/.test(e.key) &&
                                e.key !== "Backspace"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              const formattedNumber = e.target.value
                                .replace(/[^\d+]/g, "")
                                .substring(0, 13); // Limit to 13 characters including prefix
                              setFieldValue("phoneNumber", formattedNumber);
                            }}
                            className="w-full px-3 py-[6px] text-[13px] text-black h-[42px] leading-[33px] bg-transparent outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          className="text-red-500"
                        />
                        <div></div>
                      </div>
                      <div className="w-full px-[15px]">
                        <div className="w-full flex items-center bg-white border rounded-[4px]">
                          <span className="px-3 py-[6px]">
                            <FontAwesomeIcon icon={faFlag} color="black" />
                          </span>
                          <Field
                            name="address"
                            type="text"
                            placeholder="Enter Your Address"
                            className="w-full px-3 py-[6px] text-[13px] text-black h-[42px] leading-[33px] bg-transparent outline-none"
                          />
                        </div>
                        <div></div>
                      </div>
                    </div>
                    <div className="flex gap-4 sm:gap-0 flex-col sm:flex-row">
                      <div className="w-full px-[15px]">
                        <div className="w-full flex items-center bg-white border rounded-[4px]">
                          <span className="z-10 px-3 py-[6px]">
                            <FontAwesomeIcon icon={faBuilding} color="black" />
                          </span>
                          <Field
                            name="companyName"
                            type="text"
                            placeholder="Enter Your Company Name"
                            className="w-full px-3 py-[6px] text-[13px] text-black h-[42px] leading-[33px] bg-transparent outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="companyName"
                          component="div"
                          className="text-red-500"
                        />
                        <div></div>
                      </div>
                      <div className="w-full px-[15px]">
                        <div className="w-full flex items-center bg-white border rounded-[4px]">
                          <span className="px-3 py-[6px]">
                            <FontAwesomeIcon icon={faUserTag} color="black" />
                          </span>
                          <Field
                            name="designation"
                            type="text"
                            placeholder="Enter Your Designation"
                            className="w-full px-3 py-[6px] text-[13px] text-black h-[42px] leading-[33px] bg-transparent outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="designation"
                          component="div"
                          className="text-red-500"
                        />
                        <div></div>
                      </div>
                    </div>
                    <div className="flex gap-4 sm:gap-0 flex-col sm:flex-row">
                      <div className="w-full px-[15px]">
                        <div className="w-full flex items-center bg-white border rounded-[4px]">
                          <span className="z-10 px-3 py-[6px]">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              width={16}
                              height={16}
                            >
                              <path d="M326.3 218.8c0 20.5-16.7 37.2-37.2 37.2h-70.3v-74.4h70.3c20.5 0 37.2 16.7 37.2 37.2zM504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-128.1-37.2c0-47.9-38.9-86.8-86.8-86.8H169.2v248h49.6v-74.4h70.3c47.9 0 86.8-38.9 86.8-86.8z" />
                            </svg>
                          </span>
                          <Field
                            name="productServices"
                            type="text"
                            placeholder="Which product/services would you like to display"
                            className="w-full px-3 py-[6px] text-[13px] text-black h-[42px] leading-[33px] bg-transparent outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="productServices"
                          component="div"
                          className="text-red-500"
                        />
                        <div></div>
                      </div>
                      <div className="w-full px-[15px]">
                        <div className="w-full flex items-center bg-white border rounded-[4px]">
                          <span className="px-3 py-[6px]">
                            <FontAwesomeIcon icon={faUser} color="black" />
                          </span>
                          <Field
                            name="representative"
                            type="text"
                            placeholder="Who would be the representative"
                            className="w-full px-3 py-[6px] text-[13px] text-black h-[42px] leading-[33px] bg-transparent outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="representative"
                          component="div"
                          className="text-red-500"
                        />
                        <div></div>
                      </div>
                    </div>
                    <div className="flex gap-4 sm:gap-0 flex-col sm:flex-row">
                      <div className="w-full px-[15px]">
                        <div className="w-full flex items-center bg-white border rounded-[4px]">
                          <Field
                            as="select"
                            name="sponsorshipType"
                            className="text-black w-full outline-none px-3 py-[6px] h-[42px]"
                          >
                            <option value="" disabled hidden>
                              Select Sponsorship Type
                            </option>
                            <option value="Title Sponsorship (10m x 5m)">
                              Title Sponsorship (10m x 5m)
                            </option>
                            <option value="NFT Gallery Sponsorship (15m x 15m)">
                              NFT Gallery Sponsorship (15m x 15m)
                            </option>
                            <option value="Platinum Sponsorship (6m x 6m)">
                              Platinum Sponsorship (6m x 6m)
                            </option>
                            <option value="Diamond Sponsorship (6m x 6m)">
                              Diamond Sponsorship (5m x 5m)
                            </option>
                            <option value="Gold Sponsorship (6m x 3m)">
                              Gold Sponsorship (6m x 3m)
                            </option>
                            <option value="Silver Sponsorship (4m x 3m)">
                              Silver Sponsorship (4m x 3m)
                            </option>
                            <option value="Bronze Sponsorship (3m x 2m)">
                              Bronze Sponsorship (3m x 2m)
                            </option>
                          </Field>
                        </div>
                        <ErrorMessage
                          name="sponsorshipType"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 sm:flex-row">
                      <div className="w-full px-[15px]">
                        <div className="w-full flex bg-white border rounded-[4px]">
                          <span className="z-10 px-3 py-[6px]">
                            <FontAwesomeIcon icon={faComment} color="black" />
                          </span>
                          <Field
                            as="textarea"
                            name="message"
                            placeholder="Add Your Message"
                            className="resize-none w-full px-3 py-[6px] text-[13px] text-black leading-[33px] bg-transparent outline-none "
                          />
                        </div>
                        <ErrorMessage
                          name="message"
                          component="div"
                          className="text-red-500"
                        />
                        <div></div>
                      </div>
                    </div>
                    <div
                      className="g-recaptcha"
                      data-sitekey="6LcKrjUpAAAAAHjvbr0BLhRu-KbjmeLMELXrXoLb"
                    ></div>
                    <div className="px-[15px] py-4 ">
                      <button
                        type="submit"
                        className="text-center bg-[#0061a8] text-white py-[10px] px-[20px] border border-[#ccc] rounded-[4px] flex-grow-0 w-full"
                        style={{
                          boxShadow: "0 10px 45px 0 rgba(0,0,0,.15)",
                          backgroundImage:
                            "linear-gradient(90deg, #9000A3 0%, #000000 100%)",
                        }}
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </Form>
            </>
          )}
        </Formik>
      )}
      {showModal && data.qrImageBase64 && (
        <ConfirmationModal
          data={data}
          handleHomeClick={handleHomeClick}
          attendee={attendee}
          handleModalClose={handleModalClose}
        />
      )}
    </Layout>
  );
};

export default Home;
