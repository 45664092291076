import React, { useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { css } from "@emotion/react";
import "react-toastify/dist/ReactToastify.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faBuilding,
  faFlag,
  faPhoneFlip,
  faUser,
  faUserTag,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ConfirmationModal from "../../components/SuccessModal";
import { RingLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;

const Skeapership = ({ userType }) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  const [attendee, setAttendeeType] = useState("Speaker");
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
    address: Yup.string().required("Address is required"),
    companyName: Yup.string().required("Company name is required"),
    designation: Yup.string().required("Designation is required"),
    socialLink: Yup.string().required("Social link is required"),
    message: Yup.string().required("Message is required"),
  });

  const handleSocialLinkClick = (link) => {
    const validUrlRegex = /^(http|https):\/\/[\w.-]+\.[a-z]{2,}$/;
    if (validUrlRegex.test(link)) {
      window.open(link, "_blank");
    }
  };
  const handleHomeClick = () => {
    window.location.href = "https://nftexpodubai.com/";
    setShowModal(false);
    setAttendeeType("");
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <Layout
      paragraph={
        "Thank you for your interest in becoming a sponsor for the NFT Expo Dubai. Your support will play a crucial role in making this event a resounding success. Please fill out the form below with your details, and we will be in touch to discuss the exciting sponsorship opportunities available. We look forward to partnering with you and showcasing your brand to a global audience."
      }
      userType={userType}
    >
      {!showModal && (
        <Formik
          initialValues={{
            name: "",
            email: "",
            phoneNumber: "+971",
            address: "",
            companyName: "",
            designation: "",
            socialLink: "",
            message: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setLoading(true);

            axios
              .post(
                "https://node-project.gfmb.gfmbroker.com/users/speaker/register",
                values
              )
              .then((response) => {
                setData(response?.data);
                resetForm();
                setShowModal(true);
              })
              .catch((error) => {
                console.error("Error:", error);
              })
              .finally(() => {
                setLoading(false);

                setSubmitting(false);
              });
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <>
              <div className="flex justify-end mr-4">
                <button
                  className="text-center bg-[#0061a8] text-white py-[10px] px-[20px] border border-[#ccc] rounded-[16px]  max-w-[10rem] w-full mb-4"
                  onClick={handleHomeClick}
                  style={{
                    boxShadow: "0 10px 45px 0 rgba(0,0,0,.15)",
                    backgroundImage:
                      "linear-gradient(90deg, #9000A3 0%, #000000 100%)",
                  }}
                >
                  Back To Home
                </button>
              </div>
              <Form>
                {loading ? (
                  <div className="flex justify-center mt-4">
                    <RingLoader
                      color={"#ffffff"}
                      loading={loading}
                      css={override}
                      size={50}
                    />
                  </div>
                ) : (
                  <div className="flex flex-col gap-6">
                    <div className="flex gap-4 sm:gap-0 flex-col sm:flex-row">
                      <div className="w-full px-[15px]">
                        <div className="w-full flex items-center bg-white border rounded-[4px]">
                          <span className="z-10 px-3 py-[6px] ">
                            <FontAwesomeIcon icon={faUser} color="black" />
                          </span>
                          <Field
                            type="text"
                            name="name"
                            placeholder="Enter Your Name"
                            className="w-full px-3 py-[6px] text-[13px] text-black h-[42px] leading-[33px] bg-transparent outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <div className="w-full px-[15px]">
                        <div className="w-full flex items-center bg-white border rounded-[4px]">
                          <span className="px-3 py-[6px]">
                            <FontAwesomeIcon icon={faEnvelope} color="black" />
                          </span>
                          <Field
                            type="email"
                            name="email"
                            placeholder="Enter Your Email"
                            className="w-full px-3 py-[6px] text-[13px] text-black h-[42px] leading-[33px] bg-transparent outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                    <div className="flex gap-4 sm:gap-0 flex-col sm:flex-row">
                      <div className="w-full px-[15px]">
                        <div className="w-full flex items-center bg-white border rounded-[4px]">
                          <span className="z-10 px-3 py-[6px]">
                            <FontAwesomeIcon icon={faPhoneFlip} color="black" />
                          </span>
                          <Field
                            name="phoneNumber"
                            type="text"
                            placeholder="Enter Your Phone Number"
                            onKeyDown={(e) => {
                              if (
                                !/^\d$/.test(e.key) &&
                                e.key !== "Backspace"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              const formattedNumber = e.target.value
                                .replace(/[^\d+]/g, "")
                                .substring(0, 13); // Limit to 13 characters including prefix
                              setFieldValue("phoneNumber", formattedNumber);
                            }}
                            className="w-full px-3 py-[6px] text-[13px] text-black h-[42px] leading-[33px] bg-transparent outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <div className="w-full px-[15px]">
                        <div className="w-full flex items-center bg-white border rounded-[4px]">
                          <span className="px-3 py-[6px]">
                            <FontAwesomeIcon icon={faFlag} color="black" />
                          </span>
                          <Field
                            type="text"
                            name="address"
                            placeholder="Enter Your Address"
                            className="w-full px-3 py-[6px] text-[13px] text-black h-[42px] leading-[33px] bg-transparent outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                    <div className="flex gap-4 sm:gap-0 flex-col sm:flex-row">
                      <div className="w-full px-[15px]">
                        <div className="w-full flex items-center bg-white border rounded-[4px]">
                          <span className="z-10 px-3 py-[6px]">
                            <FontAwesomeIcon icon={faBuilding} color="black" />
                          </span>
                          <Field
                            type="text"
                            name="companyName"
                            placeholder="Enter Your Company Name"
                            className="w-full px-3 py-[6px] text-[13px] text-black h-[42px] leading-[33px] bg-transparent outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="companyName"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <div className="w-full px-[15px]">
                        <div className="w-full flex items-center bg-white border rounded-[4px]">
                          <span className="px-3 py-[6px]">
                            <FontAwesomeIcon icon={faUserTag} color="black" />
                          </span>
                          <Field
                            type="text"
                            name="designation"
                            placeholder="Enter Your Designation"
                            className="w-full px-3 py-[6px] text-[13px] text-black h-[42px] leading-[33px] bg-transparent outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="designation"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col sm:flex-row">
                      <div className="w-full sm:w-1/2 px-[15px]">
                        <div className="w-full flex items-center bg-white border rounded-[4px]">
                          <span className="z-10 px-3 py-[6px] ">
                            <FontAwesomeIcon
                              icon={faArrowUpRightFromSquare}
                              color="black"
                            />
                          </span>
                          <Field
                            type="text"
                            name="socialLink"
                            placeholder="Social Link"
                            onClick={(event) =>
                              handleSocialLinkClick(event.target.value)
                            }
                            className="w-full px-3 py-[6px] text-[13px] text-black h-[42px] leading-[33px] bg-transparent outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="socialLink"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 sm:flex-row">
                      <div className="w-full px-[15px]">
                        <div className="w-full flex bg-white border rounded-[4px]">
                          <span className="z-10 px-3 py-[6px]">
                            <FontAwesomeIcon icon={faComment} color="black" />
                          </span>
                          <Field
                            as="textarea"
                            name="message"
                            placeholder="Add Your Message"
                            rows="2"
                            className="resize-none w-full px-3 py-[6px] text-[13px] text-black leading-[33px] bg-transparent outline-none"
                          />
                        </div>
                        <ErrorMessage
                          name="message"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                    <div
                      className="g-recaptcha"
                      data-sitekey="6LcKrjUpAAAAAHjvbr0BLhRu-KbjmeLMELXrXoLb"
                    ></div>

                    <div className="px-[15px] py-4">
                      <button
                        type="submit"
                        className="text-center bg-[#0061a8] text-white py-[10px] px-[20px] border border-[#ccc] rounded-[4px] flex-grow-0 w-full"
                        style={{
                          boxShadow: "0 10px 45px 0 rgba(0,0,0,.15)",
                          backgroundImage:
                            "linear-gradient(90deg, #9000A3 0%, #000000 100%)",
                        }}
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </Form>
            </>
          )}
        </Formik>
      )}
      {showModal && data.qrImageBase64 && (
        <ConfirmationModal
          data={data}
          handleHomeClick={handleHomeClick}
          attendee={attendee}
          handleModalClose={handleModalClose}
        />
      )}
    </Layout>
  );
};

export default Skeapership;
