/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef } from "react";
import { RxCross1 } from "react-icons/rx";
import SocialIcons from "../SocialIcons";

const ConfirmationModal = ({
  data,
  handleModalClose,
  handleHomeClick,
  attendee,
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!data || !data.qrImageBase64) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const canvasWidth = canvas.width;
      const canvasHeight = canvasWidth / aspectRatio;

      // Clear canvas
      context.clearRect(0, 0, canvas.width, canvas.height);

      // Draw image onto canvas
      context.drawImage(img, 0, 0, canvasWidth, canvasHeight);
    };
    img.src = `data:image/jpeg;base64,${data.qrImageBase64}`;
  }, [data]);

  return (
    <div className="flex items-center justify-center">
      <div className="p-6 rounded shadow-lg text-black bg-[#b224ef] w-full max-w-md">
        <div className="flex items-center justify-end mb-2">
          <button>
            <RxCross1
              onClick={handleModalClose}
              className="text-white text-2xl"
            />
          </button>
        </div>
        <div className="flex items-center justify-center mb-1">
          <img src="/images/check.png" alt="confirmed" width={60} height={60} />
        </div>
        <h1 className="text-xl sm:text-2xl font-semibold text-center text-white">
          Registration Confirmed!
        </h1>
        <div className="flex flex-col gap-1 items-center justify-center w-full text-white font-normal mt-3">
          <div className="flex justify-between w-full">
            <h2 className="w-1/2 sm:w-auto text-sm sm:text-base">Name</h2>
            <span className="w-1/2 sm:w-auto text-right">
              {data?.details?.name}
            </span>
          </div>
          <div className="flex justify-between w-full">
            <h2 className="w-1/2 sm:w-auto text-sm sm:text-base">
              Attendee Type
            </h2>
            <span className="w-1/2 sm:w-auto text-right">{attendee}</span>
          </div>
          <div className="flex justify-between w-full">
            <h2 className="w-1/2 sm:w-auto text-sm sm:text-base">Date</h2>
            <span className="w-1/2 sm:w-auto text-right">
              11 - 12 September 2024
            </span>
          </div>
          <div className="flex justify-between w-full">
            <h2 className="w-1/2 sm:w-auto text-sm sm:text-base">Time</h2>
            <span className="w-1/2 sm:w-auto text-right">9:00 AM</span>
          </div>
          <div className="flex justify-between w-full">
            <h2 className="w-1/2 sm:w-auto text-sm sm:text-base">Venue</h2>
            <span className="w-1/2 sm:w-auto text-right">
              Festival Arena, Festival City, Dubai
            </span>
          </div>
        </div>
        <div className="flex items-center justify-center mt-20">
          <canvas
            ref={canvasRef}
            width={350}
            height={350}
            className="rounded-lg"
          ></canvas>
        </div>
        <div className="mt-5 border-t-[4px] border-dashed w-full"></div>
        <h2 className="text-white font-semibold text-[18px] mt-3 text-center">
          Share With Your Friends:
        </h2>
        <div className="mt-3">
          <SocialIcons />
        </div>
        <p className="text-lg text-center text-white font-semibold mt-4">
          THANK YOU FOR <br /> REGISTERING!
        </p>
        <p className="text-base text-center text-white font-semibold mt-1">
          You will receive an email confirmation shortly. <br />
          For further questions,
          <br /> Please contact at{" "}
          <a
            href="mailto:info@nftexpodubai.com"
            className="hover:underline text-white"
          >
            info@nftexpodubai.com
          </a>
        </p>
        <button
          className="text-center bg-[#0061a8] text-white py-[10px] px-[20px] border border-[#ccc] rounded-[4px] flex-grow-0 mt-4 w-full"
          onClick={handleHomeClick}
          style={{
            boxShadow: "0 10px 45px 0 rgba(0,0,0,.15)",
            backgroundImage: "linear-gradient(90deg, #9000A3 0%, #000000 100%)",
          }}
        >
          Back To Home
        </button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
