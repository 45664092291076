import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import Register from "./views/Register";
import Skeapership from "./views/Skipership";
import InformationView from "./views/InformationView";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home  userType="sponsor"/>} />
        <Route path="/register" element={<Register userType="visitor"/>} />
        <Route path="/speakership" element={<Skeapership userType="speaker" />} />
        <Route path="/information/:name/:id/:attendeeType/:email" element={<InformationView />} /> 
      </Routes>
    </BrowserRouter>
  );
}

export default App;
