import React from "react";

const SocialIcons = () => {
  return (
    <div className="flex gap-10 justify-center items-center">
      <a
        href="https://www.facebook.com/exponftdubai"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/images/Fb.png" alt="Facebook" width={44} height={44} />
      </a>

      <a href="https://twitter.com/" target="_blank" rel="noreferrer">
        <img src="/images/Twitter.png" alt="Facebook" width={44} height={44} />
      </a>

      <a href="https://www.instagram.com/">
        <img
          src="/images/instagram.png"
          alt="Facebook"
          width={44}
          height={44}
        />
      </a>

      <a href="https://www.linkedin.com/">
        <img src="/images/linkedIn.png" alt="Facebook" width={44} height={44} />
      </a>
    </div>
  );
};

export default SocialIcons;
