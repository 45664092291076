/* eslint-disable jsx-a11y/anchor-is-valid */
const Layout = ({ children, paragraph, userType, showRegistrationText }) => {
  let text;
  if (userType === "visitor") {
    text = "Enter your details below to Register as a Visitor";
  } else if (userType === "speaker") {
    text = "Enter your details below to Register as a Speaker";
  } else {
    text = showRegistrationText
      ? "Enter your details below to Register as a Sponsor"
      : "";
  }
  return (
    <div>
      <div className="p-[10px]">
        <a
          href="https://nftexpodubai.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/images/more-optimized-1.gif"
            className="mx-auto mt-[50px]"
            alt="logo"
          />
        </a>
      </div>
      <div className="p-[10px]">
        <h2 className="text-white text-[33px] font-[800] text-center mb-[20px]">
          WELCOME TO NFT EXPO DUBAI 2024
        </h2>
      </div>
      <div className="flex justify-center items-center">
        <div className=" m-4 w-full sm:max-w-[64.467%] p-[10px] bg-[#b224ef66] rounded-[15px] flex flex-col justify-center items-center text-white">
          <div className="text-[16px]">{text}</div>

          {showRegistrationText && (
            <div className="my-4">
              <p>
                FIELDS MARKED WITH <span className="text-[#ff0000]"> * </span>
                ARE MANDATORY
              </p>
            </div>
          )}
          <div className="my-4">
            <p className="text-center mb-[20px]">{paragraph}</p>
          </div>
          <div className=" p-5 sm:p-[30px] md:p-[70px] bg-[#ffffff85] border-0 border-[#dddddd] rounded-[4px] w-full">
            {children}
          </div>
          <div className="my-4">
            <h6>SUBSCRIBE NOW FOLLOW US FOR LATEST INFORMATION</h6>
          </div>
          <div className="pt-5 pb-10 gap-2">
            <div className="flex gap-[6px] justify-center items-center">
              {/* <div className="flex justify-center items-center w-[45px] h-[45px] bg-[#3b5998] rounded-md"> */}
              <a
                href="https://www.facebook.com/exponftdubai"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/images/Fb.png"
                  alt="Facebook"
                  width={44}
                  height={44}
                />
              </a>
              {/* </div> */}

              {/* <div className="flex justify-center items-center w-[45px] h-[45px] bg-[#1da1f2] rounded-md"> */}
              <a
                href="https://twitter.com/nftexpodubai"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/images/Twitter.png"
                  alt="Facebook"
                  width={44}
                  height={44}
                />
              </a>
              {/* </div> */}
            
              <a
                href="https://www.youtube.com/@nftexpodubai"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/images/youtube.png"
                  alt="Facebook"
                  width={44}
                  height={44}
                />
              </a>
              <a href="https://www.instagram.com/nftexpodubai/">
                <img
                  src="/images/instagram.png"
                  alt="Facebook"
                  width={44}
                  height={44}
                />
              </a>

              <a href="https://www.linkedin.com/company/nft-expo-dubai/">
                <img
                  src="/images/linkedIn.png"
                  alt="Facebook"
                  width={44}
                  height={44}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
